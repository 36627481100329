






























































import { Component, Vue } from "vue-property-decorator";
import SendIcon from "vue-material-design-icons/Send.vue";
import CloudDownloadIcon from "vue-material-design-icons/CloudDownload.vue";
import LockIcon from "vue-material-design-icons/Lock.vue";
import CellphoneIcon from "vue-material-design-icons/Cellphone.vue";

@Component({
  components: {
    SendIcon,
    CloudDownloadIcon,
    LockIcon,
    CellphoneIcon,
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== "/logout") {
      next({ name: "b-continue-to-app" });
    }
  },
})
export default class VerifyYourAccount extends Vue {
  resentCodeMsg = "";
  resentCodeCountdown = 10;

  sendSMS(): void {
    this.$store.dispatch("register/sendDownloadSMS");
  }

  resendSMS(): void {
    this.sendSMS();
    this.showSmsSentMessage();
  }

  openChat(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Beacon("open");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Beacon("navigate", "/ask");
  }

  showSmsSentMessage(): void {
    const TIMEOUT = 10000;
    this.resentCodeMsg = this.$t("SignUp_SMS_Resent") as string;
    setTimeout(() => (this.resentCodeMsg = ""), TIMEOUT);
  }
}
